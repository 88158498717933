import './App.css';
import { useEffect } from 'react';
import { Routes, Route, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Nav from './components/Nav';
import Footer from './components/Footer';
import Imprint from './pages/Imprint';

function LanguageRedirect() {
  
  const navigate = useNavigate();
  
  useEffect(() => {
    const currentPath = window.location.pathname;
    if (!currentPath.startsWith('/en') && !currentPath.startsWith('/de')) {
      const detectedLang = localStorage.getItem('i18nextLng')?.substring(0, 2) || 'en'; // Default to English if no preference
      navigate(`/${detectedLang}${currentPath}`);
    }
  }, [navigate]);

  return null; // This component does not render anything
}

function App() {

  const { t } = useTranslation();

  return (
    <div className="flex dark:text-neutral-300 justify-center text-xl min-h-[100dvh] bg-white dark:bg-neutral-900 bg-noise">
      <div className="z-10 max-w-3xl flex-1 px-5 flex flex-col">
        <Nav />
        <Routes>
          <Route path="/" element={<LanguageRedirect />} />
          <Route path="/:lang" element={
            <div className="flex-1">
              <div className="flex flex-col md:flex-row">
              <div className="w-36 my-10 md:my-0 md:ml-10 md:w-[100rem] md:order-1">
                {/* TODO: Using explicit Image url for now, because server will try to load the image from the wrong path (e.g. /de/portrait.png, it should not include the language prefix) */}
                <img className="rounded-3xl w-full" src="https://www.moritzpflueger.com/portrait-illustration.png" />  
              </div>            
                <div>
                  <h1 className="text-4xl font-bold">{t('hero_header_primary')}</h1>
                  <h2 className="text-4xl font-bold text-neutral-500">{t('hero_header_secondary')}</h2>
                  <p className="py-10">
                    {t('hero_text_1')}
                    <code className="bg-neutral-700 py-1 px-2 rounded-lg text-pink-400">React</code>
                    {t('hero_text_2')}
                    <code className="bg-neutral-700 py-1 px-2 rounded-lg text-lime-400">Vue</code>
                    .
                  </p>            
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-4 md:gap-8 py-10">
                <a 
                  className="flex items-center py-3 px-8 border-2 text-center border-black dark:border-neutral-300 font-semibold" 
                  href="https://github.com/moritzpflueger"
                  >
                  {/* TODO: Using explicit Image url for now, because server will try to load the image from the wrong path (e.g. /de/portrait.png, it should not include the language prefix) */}
                  {t('button_github')}
                  <img className="ml-5 w-9 h-9 dark:invert" src="https://www.moritzpflueger.com/github-icon.svg" />
                </a>
                <a 
                  className="flex items-center py-3 px-8 border-2 text-center border-linkedinBlue text-linkedinBlue font-semibold"
                  href="https://www.linkedin.com/in/moritz-pfl%C3%BCger-5b8b6a1b7/"
                  >
                  {t('button_linkedin')}
                  {/* TODO: Using explicit Image url for now, because server will try to load the image from the wrong path (e.g. /de/portrait.png, it should not include the language prefix) */}
                  <img className="ml-5 w-9 h-9" src="https://www.moritzpflueger.com/linkedin-icon.svg" />
                </a>
              </div>
            </div>            
          } />
          <Route path="/imprint" element={<Imprint />} />
        </Routes>
        <Footer />

        {/* <div className="bg-lime-500">Moritz Pflüger, Am Stutenanger 11A, 85764 Oberschleißheim</div> */}
      </div>
    </div>
  );
}

export default App;

import { useState , useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import i18n from '../i18n';
import DarkModeToggle from './DarkModeToggle';

const Nav = () => {

  const navigate = useNavigate();
  const [darkMode, setDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('darkMode');
    return savedTheme ? JSON.parse(savedTheme) : true; // Default to true if nothing is saved
  })
  
  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [darkMode]);

  const toggleTheme = () => {
    setDarkMode(!darkMode);
    localStorage.setItem('darkMode', !darkMode);
  };    

  const changeLanguage = (lang) => {
    if (i18n.language === lang) return;
    i18n.changeLanguage(lang);
    navigate(`/${lang}`);
  }

  return (
    <nav className="flex items-center justify-between py-10 md:py-20">
      <Link to="/">
        <img src="https://www.moritzpflueger.com/logo.png" class="w-16 dark:invert" />
      </Link>
      <ul className="flex items-center gap-5 font-bold">
        {/* <li className="text-neutral-500">PROFILE</li>
        <li className="text-neutral-500">PROJECTS</li> */}
        <li className="flex gap-3">
          <div className="bg-neutral-100 dark:bg-black flex gap-2 px-3 rounded-md">
            <button 
              className={`${!i18n.language.match('en') ? 'text-neutral-500' : ''}`}
              onClick={() => changeLanguage('en')}
            >
              EN
            </button>
            <button 
              className={`${!i18n.language.match('de') ? 'text-neutral-500' : ''}`}
              onClick={() => changeLanguage('de')}
            >
              DE
            </button>        
          </div>
          <DarkModeToggle isDarkmode={darkMode} handleToggle={toggleTheme} />
        </li>
      </ul>
    </nav>
  );
};

export default Nav;

import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer = () => {

  const { t } = useTranslation();

  return (
    <footer className="py-5 text-center text-neutral-500 mt-auto">
      <Link to="/imprint">{t('imprint')}</Link>
    </footer>
  );
};

export default Footer;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: true,
    detection: {
      order: ['path', 'navigator'],
      checkWhitelist: true,
    },
    whitelist: ['en', 'de'],
    resources: {
      en: {
        translation: {
          "hero_header_primary": "Hello, I'm Moritz",
          "hero_header_secondary": "A freelance Front End Developer from Germany based in Munich.",
          "hero_text_1": "I’m Moritz, a front end developer based in Munich, Germany. I work with leading-edge companies to create exceptional products. Before that, I built business video–on–demand products for a Cologne based media company. I’m mainly working with the most common front end frameworks like ",
          "hero_text_2": " and ",
          "button_github": "View GitHub",
          "button_linkedin": "View LinkedIn",
          "imprint": "Imprint",
        }
      },
      de: {
        translation: {
          "hero_header_primary": "Hallo, ich bin Moritz",
          "hero_header_secondary": "Ein freiberuflicher Front-End Entwickler aus Deutschland mit Sitz in München.",
          "hero_text_1": "Ich bin Moritz, ein Front-End Entwickler mit Sitz in München, Deutschland. Ich arbeite mit führenden Unternehmen zusammen, um außergewöhnliche Produkte zu schaffen. Davor habe ich Business-Video-on-Demand-Produkte für ein Kölner Medienunternehmen entwickelt. Ich arbeite hauptsächlich mit den gängigsten Front-End-Frameworks wie ",
          "hero_text_2": " und ",
          "button_github": "GitHub anzeigen",
          "button_linkedin": "LinkedIn anzeigen",
          "imprint": "Impressum",
        }
      }
    },    
  });

export default i18n;
